<template>
  <v-dialog
      v-model="showDialog"
      persistent
      max-width=600
    >
      <v-card
      min-height=250>
        <v-card-text class="pa-10">
            <p class="text-center text-h6 font-weight-bold">
              <span v-html="dialogMsg"></span>
            </p>
        </v-card-text>
        <v-card-actions class="pa-10">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              @click="$emit('confirmDialog')"
              width="150"
              height="60"
              color="#AD2A2F"
              class="white--text font-weight-bold text-h6 mr-2">
                {{okBtnText ? okBtnText : "はい"}}
            </v-btn>
            <v-btn
              @click="$emit('closeDialog')"
              width="150"
              height="60"
              color="#707070"
              class="white--text font-weight-bold text-h6">
                いいえ
            </v-btn>
            <v-spacer></v-spacer>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  props: [
    'initialShowDialog',
    // ダイアログボックスに表示するテキスト
    'dialogMsg',
    // 「OK」ボタンに表示するテキスト
    'okBtnText'],

  computed: {
    // ダイアログボックス表示の有無
      showDialog() {
        return this.initialShowDialog
      }
  }
}
</script>
<template>
  <navigation-tabs>
  <v-container class="d-flex fill-height align-start mt-4 px-8">
    <show-message/>

    <v-row class="pt-4 px-5">
      <v-col v-if="!isFileUploaded" cols=12 class="pa-0">
        <!-- ファイルがアップロードされていない場合 -->
        <span class="text-h5 el_centered_h5">「⼀次エネルギー消費量計算結果(住宅版)」PDFファイルをアップロードしてください。</span>
      </v-col>
      <v-col v-if="isFileUploaded" cols=12 class="pa-0 mb-4">
        <!-- アップロード済みの場合 -->
        <span class="text-h5 el_centered_h5">
          <span style="font-size: 1.2em;">{{ addedCnt > 0 ? addedCnt : fileCnt }} </span>
          ファイル アップロードされました。
        </span>
      </v-col>
      
      <!-- アップロード済みのファイル数 -->
      <!-- <v-col v-if="isFileUploaded" cols=12 md=3 class="mb-0 pa-0 bl_uplodedNumber" :style="{visibility: fileCnt > 0 ? 'visible' : 'hidden'}" >
        <v-card
          outlined
          width="100%"
          class="d-flex fill-height flex-column align-center text-body-2 pa-4"
          >
          <small class="text--primary text-center">アップロード済み </small>
          <div>合計<span class="text-h6 pl-2">{{ fileCnt }} </span>ファイル</div>
        </v-card>
      </v-col> -->
      <v-col v-if="isFileUploaded" cols=12 class="d-flex flex-column justify-center align-start px-0 py-0">

        <!-- アップロード済みの場合 -->
        <p class="mb-0">
          ファイルのチェックを⾏う場合は「ファイルチェック」にお進みください。
        </p>
        <p class="mb-0">
          追加のファイルがあればアップロードしてください。
        </p>
        <p class="mb-0">
          アップロードファイルの確認はファイルチェック後の⼀覧画⾯で⾏えます。
        </p>
      </v-col>
    </v-row>

    <v-row class="mt-8 d-flex align-center justify-center">
      <v-col cols="12" justify="center" align="center" class="pa-0">
        <v-alert
          class="mx-4 my-6 pa-1 primary--text text-h6">
            <vue-dropzone ref="myVueDropzone" id="dropzone"
              :options="dropzoneOptions"
              :useCustomSlot="true"
              @vdropzone-success-multiple="fileUploaded"
              @vdropzone-sending="sending"
              @vdropzone-error-multiple="fileUploadFailed"
              @vdropzone-files-added="filesAdded"
              style="background-color: #edebf47d;"
              class="primary--text"
              >
                <span class="text-no-wrap">「⼀次エネルギー消費量</span>
                <span class="text-no-wrap">計算結果(住宅版)」</span>
                <span class="text-no-wrap">ファイルを</span><br />
                <span class="text-no-wrap">ここに</span>
                <span class="text-no-wrap">ドラッグ＆ドロップ</span>
                <span class="text-no-wrap"> または 
                  <span class="text-decoration-underline">
                    参照
                  </span>
                </span>
                    
              <ul class="mt-12" style="width: fit-content; margin-left: auto; margin-right: auto; padding:0; font-size: 0.8em; list-style-type: none;">
                <li>アップロードは繰り返し⾏えます。</li>
                <li>複数ファイルを同時にアップロードできます。</li>
              </ul>
            </vue-dropzone>
          </v-alert>
      </v-col>

      <v-col cols="12" align="center">
        <v-btn
        @click="check"
        width="250"
        height="75"
        :disabled="!isFileUploaded"
        color="#5E539A"
        >
          <span class="text-h5 white--text">ファイルチェック</span>
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="mx-0 mt-10 pa-0 bl_fixedFooter">
      <v-btn
       @click="back"
       plain
       height="60"
       class="font-weight-bold text-h6 pl-0">
        <v-icon large>mdi-chevron-left</v-icon>
        戻る
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
       to="/file-check"
       v-if="toCheckCnt == 0 && isFileChecked"
       plain
       height="60"
       class="font-weight-bold text-h6 pr-10">
        次へ
        <v-icon large>mdi-chevron-right</v-icon>
      </v-btn>
    </v-row>

    <!-- ファイルアップロード済み場合の戻る処理を確認するダイアログボックス -->
    <show-dialog
      v-if="showBackDialog"
      :initialShowDialog=showBackDialog
      dialogMsg="アップロードされたファイル情報が全て失われます。<br>よろしいですか。"
      @confirmDialog=confirmBackDialog
      @closeDialog="showBackDialog = false"
    ></show-dialog>

    <!-- ファイルアップロードの進捗を表示するダイアログボックス -->
    <div
      class="progress"
      v-show="uploadProgress"
    >
      <v-progress-circular
          indeterminate
          :size="150"
          :width="15"
          color="grey"
          class="circular"
      ></v-progress-circular>
    </div>

    <!-- ファイルチェックの進捗を表示するダイアログボックス -->
    <v-dialog
      v-model="checkProgress"
      persistent
      width="600"
      >
      <v-card
        min-height=200
      >
        <v-card-text
        class="pa-16 text-center deep-purple--text text-darken-1 font-weight-bold">
            ファイルをチェックしています。<br/>
            (チェック中...<span v-if="pending + 5 < job_count">現在込み合っています</span>)<br/>
            確認済み：{{ checkedCnt }}個 / チェック予定：{{ toCheckCnt }}個
            <v-progress-linear
              class="mt-2"
              indeterminate
              color="#5E539A"
            ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-container>
  </navigation-tabs>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { sleep } from '@/util'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import NavigationTabs from '@/components/templates/NavigationTabs.vue'
import ShowMessage from '@/components/ShowMessage.vue'
import ShowDialog from '@/components/ShowDialog.vue'

export default {
  components: {
    vueDropzone: vue2Dropzone,
    ShowMessage,
    NavigationTabs,
    ShowDialog
  },
  data() {
    return {
      dropzoneOptions:{
        url: '/api/upload',
        acceptedFiles: '.pdf',
        // 最大サイズ - 1MB
        maxFilesize : 1,
        uploadMultiple: true,
        includeStyling: false,
        previewsContainer: false,
        parallelUploads: 20,
        // xhrタイムアウトの設定 - 2分
        timeout: 120000,
        // ファイルをserver側に送信する前の処理
        accept: this.acceptPdf,
        //自動的にキューに追加しない
        autoQueue : false
      },
      // 戻る処理を確認するダイアログボックス表示の有無
      showBackDialog: false,
      // 追加ファルの数
      addedCnt: 0,
      // アップロードされたファイル名
      fileNames: [],
      // ファイルアップロードの状態
      isFileUploaded: false,
      // アップロード進行中表示の有無
      uploadProgress: false,
      // エラーファイル名
      errorFileName: '',
      // ファイルチェック進行中表示の有無
      checkProgress: false,
      // チェック予定のファイル数
      toCheckCnt: 0,
      // 進捗(%)
      progress: 0,
      // チェック済みのファイル数
      checkedCnt: 0,
      // Queueに残っているジョブ全体の数
      job_count: 0,
      // 未処理のジョブの数
      pending: 0,
    }
  },

  mounted() {
    this.clearMessage()
  },
  computed: {
    ...mapGetters([
      'isError',
      'selectedHouseholdType',
      'selectedReportType',
      'fileCnt',
      'uploadedFileNames',
      'isFileChecked'
      ])
  },

  methods: {
    ...mapActions([
      'update',
      'updateMessage',
      'clearMessage',
      'updateUploadedFileNames',
      'deleteUploadedFiles',
      'checkFile',
      'checkJobStatus',
      'getCheckedResult',
      'updateCheckedResult'
      ]),

    // 戻る処理
    back() {
      // ファイルアップロード済みの場合は確認ダイアログを表示する
      if(this.fileCnt > 0) this.showBackDialog = true
      else this.$router.push('/report-type')
    },

    // ファイルをserver側に送信する前の処理
    acceptPdf(file, done) {
      this.uploadProgress = true

       // エラーファイルがある場合、全てのファイルをキャンセルする
      if (this.errorFileName) {
        done('error')
      } else {
        // メッセージのクリア
        this.clearMessage()

        // ファイルの重複チェック
        const index = this.uploadedFileNames.findIndex((fileName) => file.name == fileName)
        if (index > -1) {
          this.errorFileName = file.name
          done('duplicate')
        } else {
          done()
        }
      }
    },

    // ファイル追加の検知
    filesAdded() {
      const dz = this.$refs.myVueDropzone.dropzone
      setTimeout(()=>{
        this.errorFileName = ''

        // 最初の20ファイルをキューに追加する
        const files = dz.getAddedFiles()
        if (files.length > 0) {
            const nextfiles = files.slice(0, 20)
            dz.enqueueFiles(nextfiles)
        }

      }, 1000)
    },

    // ファイルアップロード成功する場合の処理
    async fileUploaded(files, response) {

      if (response.uploadStatus === 'success') {
        // アップロードされたファイル名を保存する
        const fileNames = files.map((file) => file.name)
        const arr = this.fileNames.concat(fileNames)
        this.fileNames = arr

        // キューに次のファイルを追加する
        const dz = this.$refs.myVueDropzone.dropzone
        const nextfiles = dz.getAddedFiles().slice(0, 20)
        if (nextfiles.length > 0) {
          dz.enqueueFiles(nextfiles)
        } else {
          // 全てのファイルがアップロード済みの場合、
          // 追加ファルの数
          if (this.fileCnt != 0) this.addedCnt = this.fileNames.length

          // アップロードされたファイル名を更新する
          this.updateUploadedFileNames(this.fileNames)

          // チェック予定のファイル数
          this.toCheckCnt = response.toCheckCnt

          this.fileNames = []
          this.uploadProgress = false
          this.isFileUploaded = true
          this.$refs.myVueDropzone.dropzone.removeAllFiles(true)
        }
      }
    },

    sending(file, xhr) {
      // バックエンドへのリクエストがタイムアウトする場合、
      xhr.ontimeout = async function() {
        // 同じアップロードにアップロード済みファイルがあれば削除する
        if (this.fileNames.length > 0) {
          await this.deleteUploadedFiles(this.fileNames)
        }

        this.fileNames = []
        this.uploadProgress = false
        this.$refs.myVueDropzone.dropzone.removeAllFiles(true)
        this.updateMessage({
          message : 'タイムアウトが発生しました。ファイルをご確認ください。',
          type: 'error'
        })
      }.bind(this)
    },

    // ファイル読み込み失敗の処理
    fileUploadFailed(files, message, xhr) {
      this.uploadProgress = false
      this.$refs.myVueDropzone.dropzone.removeAllFiles(true)

      // サーバエラー
      if (xhr) {
        let errMsg = ''

        if (xhr.status == 500) {
          errMsg = 'エラーが発生しました。しばらくしてからもう一度お試しください。'
        } else {
          errMsg = message
        }

        this.updateMessage({
          message : errMsg,
          type: 'error'
        })
      }

      // 最大サイズエラー
      if (message.includes('File is too big')) {
        this.errorFileName = this.errorFileName ? this.errorFileName + ", " + files[0].name : files[0].name
        this.updateMessage({
          message : "アップロードできるファイルは1MB以下のPDFファイルのみです。ファイルをご確認ください。" + this.errorFileName,
          type: 'error'
        })
      }

      // 重複エラー
      if (message == 'duplicate') {
        this.updateMessage({
          message : "ファイル名が重複しています。ご確認ください。" + this.errorFileName,
          type: 'error'
        })
      }

    },

    // ファイルチェック処理
    async check() {
      this.checkProgress = true
      // メッセージのクリア
      this.clearMessage()

      const jobId = await this.checkFile()

      if (!this.isError) {
        // job実行結果の確認
        const processingStatus = ['unknown','processing']
        let result = ''
        do {
            let response = await this.checkJobStatus(jobId)
            this.checkedCnt = response['checked_cnt']
            this.job_count = response['job_count']
            this.pending = response['pending']
            this.progress = response['progress']
            result = response['job_status']

            if (processingStatus.includes(result)) {
                // 1秒待ち
                await sleep(1000)
            }
        } while (processingStatus.includes(result))

        // job実行結果の取得
        if (result == 'finished') {
            const checkedResult = await this.getCheckedResult(jobId)
            if (!this.isError) {
              this.updateCheckedResult({checkedResult : checkedResult})

              this.update({isFileChecked:true})

              this.$router.push('/file-check')
            }
        }
      }

      this.checkProgress = false
    },

    // 戻る処理を確認した後ホーム画面へ遷移
    confirmBackDialog() {
      this.$router.push('/')
    }
  }
}
</script>
<style>
.v-alert__wrapper{
  max-width: 100%;
}
.bl_uplodedNumber{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
